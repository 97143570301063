<template>
  <div>
    <!-- 作者：黄楚斌 -->
    <myheader></myheader>
    <!-- 实训平台背景 -->
    <div class="header-background">
      <img src="../../assets/sxjd.jpg" alt="" />
    </div>
    <!-- 标题们 -->
    <div class="nav">
      <div class="title" hidden>
        <!-- 标题们的真正的内容 -->
        <div style="display: flex; cursor: pointer" v-for="(tl, index) in titleList" :key="'a-' + index">
          <a class="title-all" :href="'#'+tl.type">{{ tl.type }}</a>
        </div>
      </div>
      <div class="black-line"></div>
    </div>
    <!-- 标题后的内容 -->
    <div id="平台概述" style="display: flex; justify-content: center">
      <!-- Header -->
      <div>
        <div class="fixed">
          <div class="box">
            <!-- 平台概述和蓝横线 -->
            <div class="wow animate__fadeInUp">
              <div class="title-type">云计算实训室建设方案</div>
              <div style="display: flex; justify-content: center" class="">
                <div class="blue-line"></div>
              </div>
            </div>
            <!-- 图片和文本 -->
            <div class="content">
              <div class="wow animate__fadeInLeft"><img style="width: 700px; height: auto" src="../../assets/ysj1.jpg" alt="" /></div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="peyms" class="content-Box wow animate__fadeInUp">
                <!-- 培养模式的名字-->
                <div class="title1 wow animate__fadeInUp">
                    {{ trainingMode.name }}
                </div>
                <!-- 培养模式的图片1-->
                <div class="commonly">
                    <div class="commonly2">
                        <img :src="trainingMode.url" alt="" />
                    </div>
                    <!-- 培养模式的图片1-->
                    <div class="commonly1">
                        <img :src="trainingMode.url1" alt="" />
                    </div>
                </div>
    </div>
    <div id="平台概述" style="display: flex; justify-content: center">
      <!-- Header -->
      <div>
        <div class="fixed">
          <div class="box1">
            <!-- 平台概述和蓝横线 -->
            <div class="wow animate__fadeInUp">
              <div class="title-type">大数据实训室建设方案</div>
              <div style="display: flex; justify-content: center" class="">
                <div class="blue-line"></div>
              </div>
            </div>
            <!-- 图片和文本 -->
            <div class="content">
              <div class="wow animate__fadeInLeft"><img style="width: 700px; height: auto" src="../../assets/dsj1.jpg" alt="" /></div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="peyms" class="content-Box wow animate__fadeInUp">
                <!-- 培养模式的名字-->
                <div class="title1 wow animate__fadeInUp">
                    {{ trainingMode1.name }}
                </div>
                <!-- 培养模式的图片1-->
                <div class="commonly">
                    <div class="commonly2">
                        <img :src="trainingMode1.url" alt="" />
                    </div>
                    <!-- 培养模式的图片1-->
                    <div class="commonly1">
                        <img :src="trainingMode1.url1" alt="" />
                    </div>
                </div>
    </div>
    <div id="平台概述" style="display: flex; justify-content: center">
      <!-- Header -->
      <div>
        <div class="fixed">
          <div class="box1">
            <!-- 平台概述和蓝横线 -->
            <div class="wow animate__fadeInUp">
              <div class="title-type">人工智能实训室建设方案</div>
              <div style="display: flex; justify-content: center" class="">
                <div class="blue-line"></div>
              </div>
            </div>
            <!-- 图片和文本 -->
            <div class="content">
              <div class="wow animate__fadeInLeft"><img style="width: 700px; height: auto" src="../../assets/rgzn1.jpg" alt="" /></div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="peyms" class="content-Box wow animate__fadeInUp">
                <!-- 培养模式的名字-->
                <div class="title1 wow animate__fadeInUp">
                    {{ trainingMode2.name }}
                </div>
                <!-- 培养模式的图片1-->
                <div class="commonly">
                    <div class="commonly2">
                        <img :src="trainingMode2.url" alt="" />
                    </div>
                    <!-- 培养模式的图片1-->
                    <div class="commonly1">
                        <img :src="trainingMode2.url1" alt="" />
                    </div>
                </div>
    </div>
    <!-- 底部 -->
    <!-- ROUTER -->
    <mybottom></mybottom>

    <!-- BOTTOM -->
  </div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';

export default {
  components: { myheader, mybottom },
  data() {
    return {
      trainingMode: {
                name: '应用场景',
                content: '',
                url: require('../../../src/assets/ysj2.jpg'),
                url1: require('../../../src/assets/ys3.jpg'),
            },
            trainingMode1: {
                name: '应用场景',
                content: '',
                url: require('../../../src/assets/dsj2.jpg'),
                url1: require('../../../src/assets/dsj3.jpg'),
            },
            trainingMode2: {
                name: '应用场景',
                content: '',
                url: require('../../../src/assets/rgzn2.jpg'),
                url1: require('../../../src/assets/rgzn3.jpg'),
            },
      message: 'hello world',
      one: '',
      titleList: [
        {
          type: '平台概述',
          id: 1,
        },
        {
          type: '平台特色',
          id: 2,
        },
        {
          type: '应用场景',
          id: 3,
        }
      ],
      types:
        '',
      teses: [
        {
          url: require('@/assets/fangkuai.png'),
          type01: '教学平台一体设计',
          type02: '教学实训评测竞赛',
        },
        {
          url: require('@/assets/dianshi.png'),
          type01: '实训资源本地调用',
          type02: '支持院校本地调用实训资源',
        },
        {
          url: require('@/assets/tu03.png'),
          type01: '课程资源模块制作',
          type02: '对接【岗课赛证】标准',
        },
        {
          url: require('@/assets/yun04.png'),
          type01: '线上资源实时分发',
          type02: '公有云实时分发最新课程资源',
        },
      ],
      changjing: [
        {
          url: require('@/assets/guanliyuan.png'),
          identity: '课程模块',
          type: '适配学校教学场景 符合教师上课流程 路径规划 梩块课程 高效实验',
        },
        {
          url: require('@/assets/jiaoshi.png'),
          identity: '认证模块',
          type: '认证样卷多维评测分析 为训练提供参考 海量题库 认证点评 可视化结果',
        },
        {
          url: require('@/assets/xuesheng.png'),
          identity: '培训模块',
          type: '如共新职业云计算工禾野支术人员 l+X云计算平台运维与开发培训 实名认证 每日签到 过程抇照',
        },{
          url: require('@/assets/solve-one.png'),
          identity: '竞赛模块',
          type: '定期发布云计算相关趣味竞赛 锻炼学生综合素质 组队自由 获取证书 以赛促教',
        }
      ],
    };
  },
  mounted() {
    var wow = new this.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
  created() {},
  methods: {
    //  discoloration(){
    //    this.one= 'background-color: #2e71fe;'
    //  },
    //  godiscoloration(){
    //    this.one= ''
    //  }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to th is component only -->
<style scoped lang="less">
.content-Box {
    margin-top: 50px;
}
.title1 {
    margin-left: auto;
    margin-right: auto;
    color: black;
    font-weight: 600;
    font-size: 30px;
    padding-top: 65px;
    text-align: center;
}
.content-box1 {
    width: 1200px;
    height: 280px;
    background-color: #ffffff;
    box-shadow: 0px 0px 3px 3px #e4dcdc;
    margin-top: 50px;
    display: flex;
}
.content-box2 {
    width: 830px;
    height: 100px;
    margin: auto;
    font-size: 16px;
    color: black;
    margin-top: 45px;
    justify-content: center;
    letter-spacing: 0.8px;
    line-height: 2;
}
.content-Box {
    margin-top: 30px;
}

.commonly {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top:20px ;
}

.commonly1 {
    margin-left: 75px;
    width: 300px;
    height: 200px;
}
.commonly1 img{
    width: 300px;
    height: 200px;
}
.commonly2 img{
    width: 300px;
    height: 200px;
}
.header-background {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  min-width: 1200px;
  img {
    width: 100%;
  }
}
.nav {
  z-index: 999;
  position: sticky;
  top: 0px;
}
.title {
  height: 55px;
  display: flex;
  justify-content: center;
  background: #fff;
}
.black-line {
  width: 100%;
  max-width: 1920px;
  min-width: 1200px;
  height: 1px;
  background-color: black;
  opacity: 0.1;
  margin: auto;
}
.title-all {
  width: 180px;
  height: 57px;
  text-align: center;
  line-height: 57px;
  font-weight: 600;
  color: #747474;
}

.title-all:hover {
  color: #2e71fe;
  background-color: #f2f2f2;
}
.box {
  width: 1200px;
  height: 550px;
  padding-top: 60px;
}
.box1 {
  width: 1200px;
  height: 700px;
  padding-top: 60px;
}
.fixed {
  display: flex;
  justify-content: center;
  width: 1200px;
}
.title-type {
  display: flex;
  justify-content: center;
  font-weight: 800;
  font-size: 30px;
}
.blue-line {
  width: 52px;
  height: 3px;
  background-color: #6697fe;
}
.content {
  display: flex;
  justify-content: center;
  margin-top:20px ;
}
.content-type {
  margin-left: 90px;
  line-height: 28px;
  width: 600px;
  font-size: 15px;
  text-indent: 25px;
  letter-spacing: 2px;
  font-weight: 500;
}
.characteristic {
  // width: 1920px;
  height: 550px;
  background-color: #f9f9f9;
  padding-top: 70px;
}
.tese-box {
  margin-right: 30px;
  display: flex;
  text-align: center;
}
.xiao-box {
  padding-top: 36px;
  text-align: center;
  width: 280px;
  height: 270px;
  background-color: #ffffff;
}
.xiao-box:hover .img-blue-line {
  background-color: blue;
}
.img-blue-line {
  width: 60px;
  height: 4px;
  background-color: #ffffff;
  margin-left: 110px;
  margin-top: -36px;
  margin-bottom: 36px;
  // text-align: center;
}
.tese-name {
  margin-top: 35px;
  font-size: 20px;
  font-weight: 1000;
}
.tese-type {
  margin-top: 20px;
  font-size: 15px;
}
.userback {
  height: 550px;
  padding-top: 60px;
}
.liebiao {
  display: flex;
  width: 460px;
  margin-left: 130px;
  margin-bottom: 47px;
}
.zaixian {
  margin-top: 47px;
  background-color: #1c50b9;
  background-image: url(../../assets/zaixiantiyan.png);
  height: 340px;
  width: auto;
  display: flex;
  justify-content: center;
  padding-top: 65px;
  // flex-direction: row-reverse;
}
.zaixian-type {
  text-align: center;
  font-size: 30px;
  color: white;
}
.white-line {
  margin-top: 15px;
  width: 50px;
}
.zaixian-type02 {
  font-size: 17px;
  margin-top: 30px;
  color: white;
  width: auto;
}
.zaixian-anniu {
  margin-top: 50px;
  border-radius: 20px;
  background-color: #ffffff;
  width: 230px;
  margin-left: 40px;
  height: 45px;
  color: #2e71fe;
  font-size: 20px;
  font-weight: 550;
  line-height: 45px;
  text-align: center;
  // background-image: url(../../assets/yuanju01.png);
}
</style>
